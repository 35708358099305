@import 'assets/styles/_mixins';

.simplemodal-overlay {
    background-color: $color-gray-0-60;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: $z-modal;
}

.simplemodal {
    background-color: $color-white-0;
    position: fixed;
    left: 1.25rem;
    right: 17rem;
    top: 1.25rem;
    bottom: 1.25rem;
    border-radius: 0.3125rem;
    overflow: hidden;
    z-index: $z-modal;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .close {
        cursor: pointer;
        position: absolute;
        z-index: 10;
        right: 1rem;
        top: 1rem;
    }
}