$base-font-size: 16px;

$font-size-button: 14px;

$z-default: 1;
$z-viewer: 0;
$z-minus: -1;
$z-content: 10;
$z-modal: 30;
$z-navigation: 20;
$z-navigation-overlay: 19;
$z-header: 18;

$media-break-point-large-desktop: 1560;
$media-break-point-desktop: 1366;
$media-break-point-tablet: 1024;

$page-max-width: 1728px;

$border-radius-l: 15px;
$border-radius-m: 10px;
$border-radius-s: 5px;
$border-radius-xs: 3px;
$border-radius-xxs: 2px;

$card-margin-right: 18px;
$card-width: 314px;

$color-gray-0: #090f30;
$color-gray-0-50: rgba(9, 15, 48, 0.5);
$color-gray-0-60: rgba(9, 15, 48, 0.6);
$color-gray-1: #404d66;
$color-gray-2: #707070;
$color-gray-3: #979797;
$color-gray-4: #8193ae;
$color-gray-5: #d4d2d5;
$color-gray-6: #b5b7c1;
$color-gray-7: #dde4eb;
$color-gray-8: #c4c4c4;

$color-green: #2cb097;

$color-purple-0: #5368e4;
$color-purple-1: #272d68;
$color-purple-2: #768bff;
$color-purple-3: #5360aa;
$color-purple-4: #142063;
$color-purple-5: #bec8ff;
$color-purple-6: #dde1fa;
$color-purple-7: #2a3da3;
$color-purple-8: #393c8b;

$color-red-0: #cc0000;
$color-red-1: #d51a52;
$color-red-2: #e8a1b6;

$color-white-0: #ffffff;
$color-white-1: #f5f6fb;
$color-white-2: #edf2f8;
$color-white-3: #fafafa;
$color-white-4: #edeef4;
$color-white-5: #f1f3ff;
$color-white-6: #f9fafd;

$font-size-regular: 14px;

$color-midnight: #090f30;
$color-blue: #5369e5;
$color-white: #fff;
$color-black: #000;
$color-content-background: #dde4eb;

$size-sketchfab-loader: 190px;
$size-sketchfab-loader-height: 102px;
$size-sketchfab-loader-popup: 37%;
$padding-step-size: 4px;

// Transitions timing functions
$timing-default: cubic-bezier(0.4, 0, 0.2, 1);
$timing-in: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$timing-out: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$timing-grid: cubic-bezier(0.17, 0.17, 0.3, 1);

:export {
    colorGray0: $color-gray-0;
    colorGray1: $color-gray-1;
    colorGray2: $color-gray-2;
    colorGray3: $color-gray-3;
    colorGray4: $color-gray-4;
    colorGray5: $color-gray-5;
    colorGray6: $color-gray-6;
    colorGray7: $color-gray-7;
    colorGray8: $color-gray-8;
    colorGreen: $color-green;
    colorPurple0: $color-purple-0;
    colorPurple1: $color-purple-1;
    colorPurple2: $color-purple-2;
    colorPurple3: $color-purple-3;
    colorPurple4: $color-purple-4;
    colorPurple5: $color-purple-5;
    colorPurple6: $color-purple-6;
    colorWhite0: $color-white-0;
    colorWhite1: $color-white-1;
    colorWhite2: $color-white-2;
}
