@import 'assets/styles/_mixins';

.presentProjectView {
    position: relative;
    width: 100%;
    height: 100%;

    .view3dcontainer {
        position: relative;
        padding: 0;
        overflow: hidden;
        margin-bottom: -40px;
        max-width: 100%;
        width: 100%;
        height: 100%;
    }

    .productInfoContainer {
        position: absolute;
        right: padding(7);
        top: padding(7);
        width: 355px;
        @include card;

        z-index: $z-content;

        &.title {
            font-size: font-clamp(35px);
        }

        .buttonContainer {
            padding-top: padding(5);
            display: flex;

            .button {
                padding: 0 padding(5);
            }
        }
    }

    .nextProductInfoContainer {
        position: sticky;
        right: 0;
        bottom: 0;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        z-index: $z-content;

        .info {
            @include text-body-m;
            padding: padding(2) padding(7);
            background-color: $color-white-0;
            border-top-left-radius: $border-radius-s;
            border-bottom-left-radius: $border-radius-s;
            text-align: center;
            min-width: 250px;
        }

        .button {
            margin: 0;
            height: auto;
            border-radius: 0;
            width: 90px;

            svg {
                transform: scale(0.667);
            }
        }
    }
}
