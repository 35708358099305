@import 'assets/styles/_mixins';

.error-modal {
    .error-title {
        @include headline-3;
        margin-bottom: padding(6);
    }

    .error-description {
        @include text-body-m;
    }
}
