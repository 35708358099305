.loadingSpinner-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;

    .loadingSpinner {
        -webkit-animation: rotateLoader 4s infinite;
        animation: rotateLoader 4s infinite;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        display: block;
        height: 30px;
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: relative;
        top: 50%;
        width: 30px;
        z-index: 1000;

        .loadingSpinner-inner {
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            background-color: #e91e63;
            border-radius: 100%;
            display: block;
            height: 9px;
            position: absolute;
            width: 9px;

            &:nth-child(1) {
                -webkit-animation: translateBall1 1s infinite;
                animation: translateBall1 1s infinite;
                left: 0;
                top: 0;
                -webkit-transform: translate3d(4.5px, 4.5px, 0);
                transform: translate3d(4.5px, 4.5px, 0);
            }

            &:nth-child(2) {
                -webkit-animation: translateBall2 1s infinite;
                animation: translateBall2 1s infinite;
                right: 0;
                top: 0;
            }

            &:nth-child(3) {
                -webkit-animation: translateBall3 1s infinite;
                animation: translateBall3 1s infinite;
                bottom: 0;
                right: 0;
            }

            &:nth-child(4) {
                -webkit-animation: translateBall4 1s infinite;
                animation: translateBall4 1s infinite;
                bottom: 0;
                left: 0;
            }
        }
    }
}

@-webkit-keyframes rotateLoader {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    75% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotateLoader {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    75% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes translateBall1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(4.5px, 4.5px, 0);
        transform: translate3d(4.5px, 4.5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes translateBall1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(4.5px, 4.5px, 0);
        transform: translate3d(4.5px, 4.5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes translateBall2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(-4.5px, 4.5px, 0);
        transform: translate3d(-4.5px, 4.5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes translateBall2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(-4.5px, 4.5px, 0);
        transform: translate3d(-4.5px, 4.5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes translateBall3 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(-4.5px, -4.5px, 0);
        transform: translate3d(-4.5px, -4.5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes translateBall3 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(-4.5px, -4.5px, 0);
        transform: translate3d(-4.5px, -4.5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes translateBall4 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(4.5px, -4.5px, 0);
        transform: translate3d(4.5px, -4.5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes translateBall4 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    50% {
        -webkit-transform: translate3d(4.5px, -4.5px, 0);
        transform: translate3d(4.5px, -4.5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
