@import 'assets/styles/_mixins';

.button {
    @include button;
}

.button--main {
    @include button-main;
}

.button--main-alternate {
    @include button-main-alternate;
}

.button--secondary {
    @include button-secondary;
}

.button--additional {
    @include button-additional;
}

.button--navigation {
    @include button-nav;
}

.button--navigationMedium {
    @include button-nav-m;
}

.button--navigationSmall {
    @include button-nav-s;
}

.button--link {
    @include button-link;
}
