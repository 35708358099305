@import 'assets/styles/_mixins';

.view3dLoader {
    display: flex;
    width: 100%;
    height: 100%;

    .fallbackImage {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .block {
        position: relative;
        margin: auto;
        width: $size-sketchfab-loader;
        height: $size-sketchfab-loader-height;

        transition: transform 0.5s $timing-default;

        .anim {
            width: $size-sketchfab-loader;
            height: $size-sketchfab-loader-height;
            margin: 0;
            display: inline-block;
        }

        .progressBar {
            width: $size-sketchfab-loader;
            height: 5px;
            background-color: $color-content-background;
            position: absolute;
            bottom: 0;
            left: 0;

            .progress {
                width: 0%;
                height: 100%;
                background-color: $color-blue;
                transition: width 0.2s $timing-default 0s;
            }
        }
    }
}
