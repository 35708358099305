@import 'assets/styles/_mixins';

.loaderPopupContainer {
    position: absolute;
    width: 100%;
    height: 100%;

    .loaderPopup {
        span {
            color: $color-blue;
            cursor: pointer;
        }

        &.visible {
            opacity: 1;
        }

        transition: transform 0.5s $timing-default, opacity 0.6s $timing-default;

        opacity: 0;

        position: relative;
        width: calc(100% - 40px);
        text-align: center;
        padding: 20px;
        margin: 100px auto;
        font-size: $font-size-regular;
        color: $color-midnight;
    }
}
