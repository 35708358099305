@use 'sass:math';
@import '_transitions';
@import '_variables.module';

@function font-clamp-original($px) {
    $font-size-base: parseInt($base-font-size);
    @debug 'font base size: #{$font-size-base}';

    $max-font-size: calc(16 / $font-size-base);
    $min-font-size: calc(12 / $font-size-base);
    $max-width: calc(1440 / $font-size-base);
    $min-width: calc(640 / $font-size-base);

    $slope: calc(($max-font-size - $min-font-size) / ($max-width - $min-width));
    $y-axis-intersection: -$min-width * $slope + $min-font-size;

    $i: calc(parseInt($px) / $font-size-base) + rem;
    $slope-percent: ($slope * 100) + vw;

    @return clamp(
        #{$min-font-size} * #{$i},
        #{$y-axis-intersection} * #{$i} + #{$slope-percent},
        #{$max-font-size} * #{$i}
    );
}

@function font-clamp($px) {
    @return $px;
}

@function max($numbers...) {
    @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
    @return m#{i}n(#{$numbers});
}

@function padding($padding-size) {
    $padding-size-calculated: 0;
    @if ($padding-size > 10) {
        $padding-size-calculated: ($padding-size - 10) *
            4 *
            parseInt($padding-step-size);
        $padding-size: 10;
    }
    @if ($padding-size > 8) {
        $padding-size-calculated: $padding-size-calculated +
            ($padding-size - 8) *
            2 *
            parseInt($padding-step-size);
        $padding-size: 8;
    }
    $padding-size-calculated: $padding-size-calculated + $padding-size *
        parseInt($padding-step-size);

    @return $padding-size-calculated + px;
}

@function parseInt($n) {
    @return math.div($n, ($n * 0 + 1));
}

@function rem($px) {
    $font-size-base: parseInt($base-font-size);
    $i: '';
    @each $p in $px {
        @if $p != 'auto' {
            $p: (parseInt($p) / $font-size-base) + rem;
        }
        $i: #{$i} #{$p};
    }

    @return $i;
}

@mixin button {
    align-items: center;
    border-color: transparent;
    border-radius: $border-radius-s;
    border-width: 1px;
    cursor: pointer;
    display: flex;
    @include font-head;
    font-size: font-clamp(14px);
    height: 32px;
    justify-content: center;
    margin-right: 12px;
    padding: 0 padding(3);
    white-space: nowrap;

    .icon {
        margin-right: 8px;
    }

    @include button-transition;

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        transform: none;
    }
}

@mixin button-additional {
    background-color: $color-purple-6;
    border-color: $color-purple-6;
    @include button;
    color: $color-gray-0;

    svg {
        path,
        rect {
            fill: $color-purple-0;
            stroke: $color-purple-0;
        }
    }

    &:active,
    &:focus {
        background-color: $color-purple-5;
        border-color: $color-purple-5;
    }

    &:disabled,
    &[disabled] {
        background-color: $color-gray-5;
        border-color: $color-gray-5;
        color: $color-white-1;

        svg {
            path,
            rect {
                fill: $color-white-0;
                stroke: $color-white-0;
            }
        }
    }
}

@mixin button-link {
    background-color: transparent;
    border-color: transparent;
    @include button;
    @include text-body-s;
    color: $color-purple-0;
    padding-left: 0;
    padding-right: 0;

    &.underline {
        text-decoration: underline;
    }

    &:hover {
        color: $color-purple-7;
        transform: scale(1);
    }

    &:active,
    &:focus {
        color: $color-purple-1;
    }

    &:disabled,
    &[disabled] {
        color: $color-gray-5;
    }
}

@mixin button-main {
    background-color: $color-purple-0;
    border-color: $color-purple-0;
    @include button;
    color: $color-white-1;

    svg {
        path,
        rect {
            fill: $color-white-1;
            stroke: $color-white-1;
        }
    }

    &:hover {
        background-color: $color-purple-7;
    }

    &:active,
    &:focus {
        background-color: $color-purple-1;
    }

    &:disabled,
    &[disabled] {
        background-color: $color-gray-5;
    }
}

@mixin button-main-alternate {
    background-color: $color-purple-1;
    border-color: $color-purple-1;
    @include button;
    color: $color-white-1;

    svg {
        path,
        rect {
            fill: $color-white-1;
            stroke: $color-white-1;
        }
    }

    &:hover {
        background-color: $color-purple-7;
        border: 1px solid $color-purple-7;
    }

    &:active,
    &:focus {
        background-color: $color-purple-0;
        border: 1px solid $color-purple-0;
    }

    &:disabled,
    &[disabled] {
        background-color: $color-gray-5;
        border: 1px solid $color-gray-5;
    }
}

@mixin button-nav {
    background-color: $color-purple-0;
    border-color: $color-purple-0;
    border-radius: 0;
    color: $color-white-0;
    height: 40px;

    svg {
        path,
        rect {
            fill: $color-white-0;
            stroke: $color-white-0;
        }
    }

    &:hover,
    &:focus {
        background-color: $color-purple-4;
        border-color: $color-purple-4;
    }
}

@mixin button-nav-m {
    align-items: center;
    background-color: $color-white-1;
    border-color: $color-white-1;
    @include button;
    color: $color-purple-0;
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    width: 32px;

    svg {
        path,
        rect {
            fill: $color-purple-0;
            stroke: $color-purple-0;
        }
    }

    &:hover,
    &:focus {
        background-color: $color-white-4;
    }
}

@mixin button-nav-s {
    background-color: $color-white-1;
    border-color: $color-white-1;
    @include button;
    color: $color-purple-0;
    height: 25px;

    svg {
        path,
        rect {
            fill: $color-purple-0;
            stroke: $color-purple-0;
        }
    }
}

@mixin button-secondary {
    background-color: $color-white-0;
    @include button;
    border-color: $color-purple-0;
    color: $color-gray-0;

    svg {
        path,
        rect {
            fill: $color-purple-0;
            stroke: $color-purple-0;
        }
    }

    &:hover {
        background-color: $color-white-1;
    }

    &:active,
    &:focus {
        background-color: $color-purple-6;
    }

    &:disabled,
    &[disabled] {
        border-color: $color-gray-5;
        color: $color-gray-5;

        svg {
            path,
            rect {
                fill: $color-gray-5;
                stroke: $color-gray-5;
            }
        }
    }
}

@mixin card {
    background-color: $color-white-0;
    border-radius: $border-radius-l;
    padding: padding(7);
    @include shadow-m;

    &.title {
        @include headline-1;
    }
}

@mixin card-alternate {
    @include card;
    padding: padding(5);

    &.title {
        font-size: font-clamp(19px);
        @include headline-5;
    }
}

@mixin card-item {
    background-color: $color-white-0;
    border-radius: $border-radius-l;
    flex-basis: $card-width;
    flex-grow: 0;
    flex-shrink: 0;
    padding: padding(3) padding(3) padding(4);
    @include shadow-m;

    &:hover {
        @include shadow-m-hover;
    }

    .image {
        border-radius: $border-radius-l;
        height: auto;
        width: 100%;
    }

    .content {
        padding: padding(4) padding(1) 0;

        .title {
            margin-bottom: 8px;
            @include text-body-l;
        }

        .subtitle {
            margin-bottom: 12px;
            @include text-body-s;
            color: $color-gray-1;
        }

        .text {
            margin-bottom: 12px;
            @include text-body-xs;
            color: $color-gray-2;
        }
    }
}

@mixin font-text {
    font-family: 'ff-real-text-pro', sans-serif;
}

@mixin font-head {
    font-family: 'ff-real-headline-pro', sans-serif;
}

@mixin headline-1 {
    color: $color-gray-0;
    @include font-head;
    font-size: font-clamp(36px);
    font-weight: 700;
    line-height: 128%;
}

@mixin headline-2 {
    color: $color-gray-0;
    @include font-head;
    font-size: font-clamp(32px);
    font-weight: 600;
    line-height: 120%;
}

@mixin headline-3 {
    color: $color-gray-0;
    @include font-head;
    font-size: font-clamp(26px);
    font-weight: 700;
    line-height: 124%;
}

@mixin headline-4 {
    color: $color-gray-0;
    @include font-head;
    font-size: font-clamp(24px);
    font-weight: 700;
    line-height: 132%;
}

@mixin headline-5 {
    color: $color-gray-0;
    @include font-head;
    font-size: font-clamp(24px);
    font-weight: 200;
    line-height: 158%;
}

@mixin hidden {
    display: none;
}

@mixin shadow-l {
    box-shadow: 0px 4px 20px 0px rgba(9, 15, 48, 0.15);
}

@mixin shadow-m {
    box-shadow: 0px 4px 15px 0px rgba(83, 104, 228, 0.1);
}

@mixin shadow-s {
    box-shadow: 0px 4px 15px 0px rgba(83, 104, 228, 0.05);
}

@mixin shadow-l-hover {
    box-shadow: 0px 4px 20px 0px rgba(9, 15, 48, 0.5);
}

@mixin shadow-m-hover {
    box-shadow: 0px 4px 15px 0px rgba(83, 104, 228, 0.35);
}

@mixin shadow-s-hover {
    box-shadow: 0px 4px 15px 0px rgba(83, 104, 228, 0.25);
}

@mixin tab {
    border-bottom: 1px solid $color-gray-7;
    color: $color-gray-0;
    font-size: font-clamp(14px);
    @include font-text;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 136%;
    margin-right: 28px;

    &.active,
    &:hover {
        border-color: $color-purple-0;
        color: $color-purple-0;
    }
}

@mixin text-body-l {
    color: $color-gray-0;
    @include font-head;
    font-size: font-clamp(18px);
    font-weight: 600;
    line-height: 144%;
}

@mixin text-body-m {
    color: $color-gray-0;
    @include font-head;
    font-size: font-clamp(16px);
    font-weight: 400;
    line-height: 150%;
}

@mixin text-body-r {
    color: $color-gray-0;
    @include font-text;
    font-size: font-clamp(18px);
    font-weight: 400;
    line-height: 144%;
}

@mixin text-body-s {
    color: $color-gray-0;
    @include font-head;
    font-size: font-clamp(12px);
    font-weight: 400;
    line-height: 148%;
}

@mixin text-body-xs {
    color: $color-gray-0;
    @include font-text;
    font-size: font-clamp(10px);
    font-weight: 400;
    line-height: 120%;
}

@mixin tree-view {
    list-style: none;
    margin: 0;
    padding: 0;

    ul {
        list-style: none;
        margin: 0 0 0 padding(3);
        padding: 0;
    }

    li {
        border-left: 1px solid $color-gray-5;
        margin: 0;
        padding: 0 padding(8);
        position: relative;

        &:before {
            border-bottom: 1px solid $color-gray-5;
            color: $color-white-0;
            content: '';
            display: block;
            height: 1rem;
            left: 0;
            position: absolute;
            top: -0.7rem;
            width: 12px;
        }

        &:last-child {
            border-left: none;

            &:before {
                border-left: 1px solid $color-gray-5;
            }

            &:first-child {
                &:before {
                    height: 0.5rem;
                    top: -0.1rem;
                }
            }
        }
    }
}

@mixin max-page-width {
    max-width: $page-max-width;
    margin: auto;
}
