@import 'assets/styles/_general';
@import 'assets/styles/_mixins';

#baxter-isa-tool {
    box-sizing: border-box;
    color: $color-gray-0;

    @include font-text;
    font-size: font-clamp(14px);
    font-weight: 400;

    height: calc(100vh);
    margin: 0;
    padding: 0;
    width: 100%;

    > div {
        height: 100%;
    }

    .page-container {
        height: calc(100vh);

        > div:nth-child(2),
        > div:nth-child(3) {
            z-index: 1;
        }
    }

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
}
