@mixin button-transition {
    transform: scale(1);
    transition: all 0.24s $timing-in;

    svg {
        path,
        rect {
            transition: all 0.24s $timing-in;
        }
    }
}

@mixin pane-transition-start {
    display: none;
    opacity: 0;
    transform: scale(0.92);
    transform-origin: top right;
    transition: opacity 0.15s $timing-default, transform 0.15s $timing-default;
}

@mixin pane-transition($prefix, $class) {
    .#{$prefix}-enter,
    .#{$prefix}-enter-done,
    .#{$prefix}-exit {
        .#{$class} {
            display: block;
        }
    }

    .#{$prefix}-enter-active,
    .#{$prefix}-enter-done {
        .#{$class} {
            opacity: 1;
            transform: scale(1);
        }
    }
}

@mixin sidebar-transition {
    .sidebar-overlay {
        opacity: 0;
        transition: opacity 0.5s $timing-default;
    }
    .sidebarContent-container {
        transform: translateX(-322px);
        transition: transform 0.3s $timing-default;
    }

    .sidebar-transition-enter,
    .sidebar-transition-enter-done,
    .sidebar-transition-exit {
        .sidebar-container {
            display: block;
        }
    }

    .sidebar-transition-enter-active,
    .sidebar-transition-enter-done {
        .sidebar-overlay {
            opacity: 1;
        }
        .sidebarContent-container {
            transform: translateX(0);
        }
    }
}

@mixin grid-transition {
    .grid .grid-item,
    .list .grid-item {
        opacity: 0;
        transform: translateY(20px) scale(1);
    }

    .list.updated,
    .grid.updated,
    .list.updated .header > div {
        opacity: 0;
    }

    .grid-transition-enter,
    .grid-transition-enter-done {
        @for $i from 1 through 150 {
            .grid .grid-item:nth-child(#{$i}n),
            .list .grid-item:nth-child(#{$i}n) {
                transition: opacity 0.333s $timing-grid #{$i * 0.05}s,
                    transform 0.333s $timing-grid #{$i * 0.05}s,
                    box-shadow 0.24s $timing-default 0s;
            }
        }

        .list,
        .grid {
            transition: opacity 0.333s $timing-grid 0s;
        }

        @for $i from 1 through 10 {
            .list .header > div:nth-child(#{$i}n) {
                transition: opacity 0.333s $timing-grid #{0.15 + ($i * 0.05)}s;
            }
        }
    }

    .grid-transition-enter-active,
    .grid-transition-enter-done {
        .grid .grid-item,
        .list .grid-item {
            opacity: 1;
            transform: translateY(0);
        }

        .list,
        .grid,
        .list .header > div {
            opacity: 1;
        }
    }
}
