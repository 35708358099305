$menu-size-closed: 50px;
$menu-size-opened: 200px;
$transition-duration: 0.2s;

.main-menu {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;

    &.main-menu-closed {
        cursor: pointer;

        section.toggle {
            opacity: 1;
            translate: 0 0;
        }

        section.content {
            opacity: 0;
            translate: 50% -50%;
            scale: 0 0;
            pointer-events: none;
        }
    }

    &.main-menu-opened {
        section.toggle {
            opacity: 0;
            translate: -100% 0;
            pointer-events: none;
        }

        section.content {
            opacity: 1;
            scale: 1 1;
            translate: none;
        }
    }

    section {
        border-radius: 5px;
        background: #ffffff;
        position: relative;
        
        &.toggle {
            position: absolute;
            top: 0;
            right: 0;
            width: $menu-size-closed;
            height: $menu-size-closed;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            opacity: 1;
            scale: 1;
            translate: none;
            transition: opacity $transition-duration, scale $transition-duration, translate $transition-duration;
        }

        &.content {
            box-sizing: content-box;
            padding: 16px;
            width: $menu-size-opened;
            scale: 1;
            translate: none;
            transition: opacity $transition-duration, scale $transition-duration, translate $transition-duration;

            .header, .content, .footer {
                padding: 20px 10px;
    
                button {
                    border: none;
                    outline-color: none;
                    margin: 0 0 12px 0;
                    color: #585C77;
                    background: rgba(218, 218, 218, 0.25);
                    border-radius: 3px;
                    display: flex;
                    flex: 1 1 auto;
                    padding: 9.487px 12.649px 9.487px 15px;
                    align-items: flex-start;
                    gap: 9.487px;
                    align-self: stretch;
                    cursor: pointer;
    
                    &:last-child {
                        margin: 0;
                    }

                    &:hover {
                        background: rgba(218, 218, 218, 0.5);
                    }
                }
                
                &:last-child {
                    padding-bottom: 0;
                }
            }
    
            .header {
                position: relative;
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                padding-top: 3px;
                padding-bottom: 15px;
    
                .close {
                    cursor: pointer;
                    position: absolute;
                    z-index: 10;
                    right: 0;
                    top: 0;
                }
    
                .sub-title {
                    color: #181818;
                    opacity: 0.5;
                    font-family: ff-real-headline-pro, sans-serif;
                    font-size: 8px;
                    line-height: 25px;
                    font-style: normal;
                    font-weight: 375;
                    letter-spacing: 0.4px;
                    text-transform: uppercase;
                }
    
                .title {
                    div:nth-child(1) {
                        text-transform: uppercase;
                        color: #181818;
                        font-family: ff-real-headline-pro, sans-serif;
                        font-size: 18.974px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20.1px;
                    }
                    div:nth-child(2) {
                        color: #181818;
                        font-family: ff-real-headline-pro, sans-serif;
                        font-size: 18.974px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: 20.1px;
                    }
                }
            }
    
            .content {
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                display: flex;
                flex-direction: column;
    
                div {
                    font-family: ff-real-headline-pro, sans-serif;
                    font-size: 12.65px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18.974px;
                }
    
                .viewpoints {
                    color: #000;
                    font-family: 'Inter', sans-serif;
                    padding: 0 5px 15px 5px;
                    font-weight: 600;
                }
            }
    
            .footer {
                display: flex;
                justify-content: space-between;
                font-family: ff-real-headline-pro, sans-serif;
                padding-top: 12px;
            }
        }
    }
}
