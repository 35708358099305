@import 'assets/styles/_mixins';

.modal-overlay {
    background-color: $color-gray-0-60;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-modal;

    .modal {
        background-color: $color-white-0;
        left: 50%;
        min-width: 300px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, calc(-50% - 36px));

        &.modal--rounded {
            border-radius: $border-radius-l;
        }

        .modal-header {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 48px;
            justify-content: flex-end;

            svg {
                path {
                    fill: $color-purple-4;
                }
            }
        }

        .modal-content {
            padding: 0 padding(10) padding(10);
        }
    }
}
