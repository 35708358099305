@import 'assets/styles/_mixins';

.presentMode {
    .mainHeaderContent-container {
        .button--compare {
            &:disabled {
                background-color: $color-purple-0;
                opacity: 0.3;
            }
        }
    }
}

.presentProjectView {
    background-color: $color-white-1;
}
